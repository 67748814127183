import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { DataRoutes, Pages } from '../utils/routes.utils';
import Landing from './landing.screen';
import { Container } from '@mui/material';
import Home from './home.screen';
import HomePage from './homepage';
import Tabbar from '../components/tabbar.component';
import Programmation from './programmation.screen';
import LogoPurple from '../assets/images/LogoPurple.svg';
import LogoNeon from '../assets/images/folks-neon.svg';
import { Typography } from '@mui/material';

const Screens = React.memo(() => {

  return (
    <>
    <Container style={{padding: 0, margin: 0, maxWidth: 'unset', height: '100%',
      background: 'linear-gradient(to bottom,#803EBF 0%,  #8644C3 30%,    #9E54D0 45%,    #B75EC6 50%,    #D15A3B 55%,    #E65D22 70%,    #FF621D 100%)',
    }}>
      {/* <Tabbar routes={DataRoutes.routes}/>
      <Routes>
        <Route path='*' element={<Navigate to={Pages.LANDING} replace />} />
        <Route path='*' element={<Navigate to={Pages.HOMEPAGE} replace />} />
        <Route element={<Landing/>} path={Pages.LANDING} />
        <Route path={Pages.HOME} element={<Home/>} />
        <Route path={Pages.PROG} element={<Programmation/>} />
        <Route path={Pages.HOMEPAGE} element={<HomePage/>} />
      </Routes> */}
      <div style={{
        display: 'flex', 
        justifyContent: 'center', 
        height: '100%',
        alignItems: 'center', 
        flexDirection: 'column', 
        fontSize: '36px',
          color: '#00FFFE',
          textShadow: '0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent',
      }}>
      <img src={LogoNeon} alt='' style={{height: '180px'}} />
        <div style={{
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          flexDirection: 'column', 
          fontSize: '36px',
          marginTop: '-10px',
          color: '#00FFFE',
          textShadow: '0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE'
        }}>
          <Typography fontSize={{xs: 60, sm: 100}}>FOLKS</Typography>
          <Typography align='center' style={{textTransform: 'uppercase', marginTop: '-20px'}}>Festival de musiques traditionnelles revisitées</Typography>
          <Typography style={{marginTop: '-20px'}} fontSize={90} fontFamily='Mistral'>
            Marseille
          </Typography>
          <Typography 
            fontSize={{xs: 30, sm: 70}}
            style={{
              border: '2px solid #00FFFE',
              padding: '20px',
              borderRadius: '30px',
              boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent'
            }}
            >{"2 → 12.04.25"}</Typography>
        </div>
      </div>
    </Container>
    </>
  )
});

export default Screens;