import { createTheme } from '@mui/material';
import { Colors } from '../constants/colors.constants';
import card from './card.theme';
import button from './button.theme';
import fonts from '../assets/fonts/index';

const theme = createTheme({
  typography: {
    // fontFamily: 'Georgia'
    fontFamily: 'DroulersClarendon-Line'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `${fonts}
      html {
        height: 100%;
        // -webkit-font-smoothing: auto;
      }
      body {
        height: 100%;
        scrollbar-width: thin;
        
      }
      div#root {
        height: 100%;
        white-space: pre-line;
        background: 'linear-gradient(to bottom,#803EBF 0%,  #8644C3 30%,    #9E54D0 45%,    #B75EC6 50%,    #D15A3B 55%,    #E65D22 70%,    #FF621D 100%)';
      }
      `
    },
    ...button,
    ...card,
  }
});

export default theme;